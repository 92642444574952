@import 'layout.css';
@import 'common.css';
@import '../../../iadocs/assets/style/iadocs.css';
@import 'common.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @keyframes dots {
    0% {
      content: ''
    }
    33% {
      content: '.'
    }
    66% {
      content: '..'
    }
    100% {
      content: '...'
    }
  }
  html, body {
    @apply font-primary text-primary dark:text-primary-dark font-light h-screen m-0 text-[13px];
  }

  #root {
    @apply h-full;
    white-space: pre-line;
  }

  .dots::after {
    content: '';
    animation: dots 1s steps(3, end) infinite;
  }
}

