.layout-container {
  @apply text-[13px] font-primary bg-white-150 text-primary flex flex-col h-full;
  @apply dark:text-primary-dark dark:bg-black;
}

.layout-header {
  @apply z-50 bg-white dark:bg-gray-1600 flex justify-between items-center min-h-[90px] shadow-bottom dark:shadow-dark-bottom;
}

.toggle-button-dt {
  @apply md:block hidden;

}

.toggle-button-dt-icon {
  @apply ml-3;
}

.dark-theme-wrapper {
  @apply flex cursor-pointer items-center md:mr-60px mr-[20px] hover:text-blue-1350 text-gray-1350 dark:text-gray-1250;
}

.footer {
  @apply mt-auto md:flex hidden justify-between items-end p-4 md:px-16 bg-white-150 dark:bg-black;

  .sub-footer {
    @apply mt-auto flex justify-between items-end p-4;
  }
}

.layout-application, .layout-prompt {
  @apply bg-white-150 font-primary text-primary flex flex-col min-h-screen;
  @apply dark:text-primary-dark dark:bg-black;

  .toggle-button {
    @apply md:block hidden text-white;
    @apply dark:text-black;
  }

  .toggle-button-icon {
    @apply ml-3 text-white;
    @apply dark:text-black;
  }

  .footer {
    @apply md:flex hidden justify-between items-end p-4 md:px-24;

    .sub-footer {
      @apply mt-auto md:flex hidden justify-between items-end p-4;
    }
  }
}

.outlet-container {
  @apply flex-grow flex text-[13px] bg-white-150 dark:bg-gray-1600 h-full;
}

.logo-lauria {
  @apply h-60px w-[180px] md:ml-60px ml-[15px] cursor-pointer;
}

.sub-header {
  @apply flex flex-col  w-full bg-gray-1325 h-[90px] dark:bg-gray-1425 text-white dark:text-primary-dark;
  @apply p-[10px];
}