.upload-document {
    @apply flex flex-col w-[400px] h-[300px] text-center mt-[15px];
}

h3.upload-title {
    @apply mt-[20px] font-semibold text-[18px] flex items-center justify-center rounded;
}

p.upload-description {
    @apply text-center text-[18px] leading-[30px] mt-10px;
}

.inactive {
    @apply h-full bg-white dark:bg-black border-gray-1225 dark:border-blue-1350 border-2 rounded-[30px] border-dashed;
    @apply hover:bg-blue-1150 hover:cursor-pointer dark:hover:bg-gray-1450;
}

.active {
    @apply h-full bg-blue-1150 border-blue-1400 border-2 rounded-[30px] border-dashed;
}

.error {
    @apply h-full bg-red-1100 border-red-1300 border-2 rounded-[30px] border-dashed;
}

.dragzone-error {
    @apply w-[540px] text-left text-red-1300;
}

.uploaded-document {
    @apply w-[600px] bg-white dark:bg-black flex flex-row mt-[15px] items-center border-gray-1050 dark:border-gray-1350 border rounded-[15px] p-[15px] relative;
}

.uploaded-document-description {
    @apply flex flex-col items-start w-full pl-[20px];
}

.uploaded-document-description h4 {
    @apply uppercase font-semibold text-[20px] h-[45px] flex items-center text-left;
}

.uploaded-document-description-p {
    @apply flex flex-col items-center text-left;
}

.document-card-extract, .document-card-typing, .document-card-unbundle {
    @apply bg-white dark:bg-black w-[450px] h-[430px] border-gray-1050 dark:border-gray-1350 border-2 rounded-[30px] mt-[60px];
}

.document-header-extract, .document-header-typing, .document-header-unbundle {
    @apply w-[420px] h-[120px] rounded-t-[30px];
}

.document-header-extract {
    @apply mx-auto mt-[15px] bg-green-1050 dark:bg-gray-1650;
}

.document-header-typing {
    @apply mx-auto mt-[15px] bg-green-1100 dark:bg-gray-1675;
}

.document-header-unbundle {
    @apply mx-auto mt-[15px] bg-purple-1000 dark:bg-gray-1700;
}

.document-image-extract {
    @apply relative z-10;
}

.document-image-extract svg {
    @apply absolute top-[30px] left-[167px];
}

.document-image-typing {
    @apply relative z-10;
}

.document-image-typing svg {
    @apply absolute top-[30px] left-[150px];
}

.document-image-unbundle {
    @apply relative text-purple-1150 z-10;
}

.document-image-unbundle svg {
    @apply absolute top-[30px] left-[132px];
}

.document-card-extract {
    @apply hover:bg-green-1050 hover:border-green-1400 hover:dark:bg-gray-1650 hover:dark:border-blue-1450;
}

.document-card-typing {
    @apply hover:bg-green-1100 hover:border-green-1500 hover:dark:bg-gray-1675 hover:dark:border-green-1500;
}

.document-card-unbundle {
    @apply hover:bg-purple-1000 hover:border-purple-1150 hover:dark:bg-gray-1700 hover:dark:border-purple-1150;
}


.document-content h2 {
    @apply mt-[30px] h-[90px] font-semibold text-[30px] flex items-center justify-center;
}

.h2-extract {
    @apply text-blue-1450;
}

.h2-typing {
    @apply text-green-1500;
}

.h2-unbundle {
    @apply text-purple-1150;
}

.document-content p {
    @apply text-center text-[20px] leading-[30px];
}

.document-content button {
    @apply flex flex-row items-center justify-center mx-auto;
    @apply w-[180px] h-[60px] text-[20px] font-semibold bg-blue-1150 dark:bg-black rounded-[10px] text-blue-1400;
    @apply dark:border dark:border-purple-1100 dark:text-purple-1100;
    @apply hover:dark:text-purple-1100 hover:dark:bg-purple-1700;
}

.document-content button svg {
    @apply ml-[30px];
}

.select-document-type {
    @apply border w-[320px] h-[40px] block mx-auto text-primary my-[10px] pl-[10px] dark:bg-gray-1600 dark:border-gray-1350;
}


.api-data-header {
    @apply w-[600px] bg-white flex flex-row mt-[30px] items-center border-gray-1050 border rounded-[15px] px-[30px] py-[20px];
    @apply dark:bg-black dark:border-gray-1350;
}

.api-data-header svg {
    @apply h-[90px];
}

.api-data-header h3 {
    @apply font-semibold text-[20px] uppercase ml-[10px];
}

.api-data-header p {
    @apply text-[20px] ml-[10px];
}

.api-data-header-unbundle {
    @apply flex flex-row w-[600px];
}

.api-data-header-light {
    @apply w-[230px] bg-white flex flex-row mt-[30px] items-center border-gray-1050 border rounded-[15px] pr-[10px] py-[20px];
}

.api-data-header-light svg {
    @apply h-[90px];
}

.api-data-header-light p {
    @apply text-[14px];
}

.api-data-header-pagination {
    @apply w-[330px] flex flex-col mt-[30px] ml-[40px] py-[20px];
}

.pagination {
    @apply h-[60px] flex flex-row items-center;
}

.pagination ul {
    @apply inline-flex items-center;
}

.pagination ul li {
    @apply items-center ml-[10px];
}

.pagination ul li:first-child {
    @apply ml-0;
}

.pagination ul li a {
    @apply cursor-pointer w-[45px] h-[45px] block text-[20px] flex items-center justify-center font-semibold text-blue-1400 bg-white rounded-[45px] border border-blue-1400;
}

.pagination ul li a.selected {
    @apply w-[60px] h-[60px] text-[30px] bg-blue-1400 text-white;
}

.pagination ul svg {
    @apply ml-[10px];
}

.api-data-header-pagination p {
    @apply text-[14px] mt-[10px];
}

.api-data-display, .api-data-display-unbundle {
    @apply w-[600px] bg-white my-[30px];
    @apply dark:bg-black dark:border-gray-1350;
}

.api-data-display table, .api-data-display-unbundle table {
    @apply w-full;
}

.api-data-display table thead, .api-data-display-unbundle table thead {
    @apply h-[70px] text-blue-1800 dark:text-gray-1250 text-[16px] font-semibold uppercase;
}

.api-data-display-unbundle table thead {
    @apply bg-purple-1000 dark:bg-gray-1700;
}

.api-data-display table thead th, .api-data-display-unbundle table thead th {
    @apply text-left px-[20px];
}

.table-header {
    @apply flex flex-row justify-between items-center;
}

.button-download {
    @apply h-[50px] flex flex-row bg-blue-1150 text-blue-1400 rounded-[10px] items-center px-[30px] font-light uppercase text-[18px];
    @apply hover:bg-blue-1400 hover:text-white hover:dark:text-purple-1100 hover:dark:bg-purple-1700;
    @apply dark:bg-blue-1350 dark:text-black;
}

.button-download svg {
    @apply ml-[20px];
}

.api-data-display table tbody td, .api-data-display-unbundle table tbody td {
    @apply p-[10px] border dark:border-gray-1350;
}

.api-data-display table tbody td.table-key-header, .api-data-display-unbundle table tbody td.table-key-header {
    @apply h-[40px] text-blue-1800 bg-white text-[14px] font-semibold uppercase;
}

.api-data-display table tbody td.table-key, .api-data-display-unbundle table tbody td.table-key {
    @apply w-[220px];
}

.api-data-display table thead {
    @apply bg-green-1050 dark:bg-gray-1700;
}

.error-field {
    @apply border-red-1300 bg-red-1100;
}

.error-message {
    @apply text-[20px] text-red-1300;
}

.upload-button {
    @apply mx-auto;
}